import {
    ABTC,
    ArtificialIntelligence,
    Blog,
    CBC,
    Canada,
    DXC,
    DataScience,
    DesktopApplications,
    DigitalExperience,
    EmbeddedSystems,
    KPI,
    Loom,
    MobileApplications,
    OXY,
    Pakistan,
    SilkRoad,
    SystemsEngineering,
    TechCo,
    USA,
    WebApplications,
    CyberSecurity,
    ItemOne,
    ItemTwo,
    ItemThree,
    ItemFour,
    ItemFive,
    ItemSix,
    ItemSeven,
    ItemEight,
    ItemNine,
    ItemTen,
    ItemEleven,
    ItemTwelve,
    ItemThirteen,
    ItemFourteen,
    ItemFifteteen,
    SliderItem4,
    SliderItem3,
    SliderItem2,
    SliderItem1,
    SliderItem5,
    SliderItem6,
    SliderItem7,
    SliderItem8,
    cardOne,
    cardTwo,
    cardThree,
    cardFour,
    cardFive,
    cardSix,
    cardSeven,
    Project1,
    Project2,
    Project3,
    Project4,
    Project5,
    Project6,
    Project7,
    Project8,
    Website1,
    Website2,
    Website3,
    Website4,
    Website5,
    Website6,
    Website7,
    Website8,
    WebApp1,
    WebApp2,
    WebApp3,
    WebApp4,
    WebApp5,
    WebApp6,
    WebApp7,
    WebApp8,
    MobileApp1,
    MobileApp2,
    MobileApp3,
    MobileApp4,
    MobileApp5,
    MobileApp6,
    MobileApp7,
    MobileApp8,
    UIUX1,
    UIUX2,
    UIUX3,
    UIUX4,
    UIUX5,
    UIUX6,
    UIUX7,
    UIUX8,
    DataScience1,
    DataScience2,
    DataScience3,
    DataScience4,
    DataScience5,
    DataScience6,
    DataScience7,
    DataScience8,
} from "../../img/AllImages";

const CardSecData = [
    {
        id: 1,
        cardSecImg: cardOne,
        cardSecIcon: DataScience,
        cardSecHeading: "Data Science",
        cardSecContent: "Data analysis, data visualization, machine learning model development, predictive analytics, data mining, big data management",
        cardSecLink: 'dataScience',
    },
    {
        id: 2,
        cardSecImg: cardTwo,
        cardSecIcon: ArtificialIntelligence,
        cardSecHeading: "Artificial Intelligence",
        cardSecContent: "Chatbot development, virtual assistant development, intelligent automation, image recognition, speech recognition, predictive maintenance, machine learning ",
        cardSecLink: 'artificialIntelligence',
    },
    {
        id: 3,
        cardSecImg: cardThree,
        cardSecIcon: ItemTwelve,
        cardSecHeading: "Web Applications",
        cardSecContent: "Web app development, e-commerce website development, custom web software development, web application testing and validation",
        cardSecLink: 'webApplications',
    },
    {
        id: 4,
        cardSecImg: cardFour,
        cardSecIcon: ItemEleven,
        cardSecHeading: "Mobile Applications",
        cardSecContent: "iOS app development, Android app development, cross-platform app development, mobile app testing and validation.",
        cardSecLink: 'mobileApplications',
    },
    {
        id: 5,
        cardSecImg: cardFive,
        cardSecIcon: ItemFifteteen,
        cardSecHeading: "Blockchain",
        cardSecContent: "We help organizations boost efficiency, agility and security through custom blockchain development services.",
        cardSecLink: 'blockchain',
    },
    {
        id: 6,
        cardSecImg: cardSix,
        cardSecIcon: CyberSecurity,
        cardSecHeading: "Cyber Security",
        cardSecContent: "Our cybersecurity experts work tirelessly to help businesses protect their critical data, valuable assets and systems from cyber threats by designing and implementing comprehensive security solutions.",
        cardSecLink: 'cyberSecurity',
    },
    {
        id: 7,
        cardSecImg: cardSeven,
        cardSecIcon: ItemEight,
        cardSecHeading: "Quality Assurance",
        cardSecContent: "Our QA experts are committed that every solution we provide meets the quality and reliability requirements.",
        cardSecLink: 'qualityAssurance',
    },
]

const CardFlagData = [
    {
        id: 1,
        cardFlagSecImg: Canada,
        cardFlagSecHeading: "Canada",
        cardFlagSecHeadingTwo: "Ontario",
        cardFlagSecContent: "Techizh and The AAAAAAAAA Experts partnered together to develop customized safety-critical embedded systems and provide Quality Assurance services to leading brands in the automotive industry.",
        cardFlagSecLinkText: "aaaaaaaaa.com",
        cardFlagSecLink: "/",
    },
    {
        id: 2,
        cardFlagSecImg: USA,
        cardFlagSecHeading: "USA",
        cardFlagSecHeadingTwo: "California",
        cardFlagSecContent: "CCCCCC Solution, a joint venture of Techizh and DDDD aims at providing holistic solutions comprised of RFID hardware design, middleware, software and Enterprise Application Development.",
        cardFlagSecLinkText: "dddd.com,  ccccccc-solution.com",
        cardFlagSecLink: "/",
    },
    {
        id: 3,
        cardFlagSecImg: Pakistan,
        cardFlagSecHeading: "Pakistan",
        cardFlagSecHeadingTwo: "Lahore",
        cardFlagSecContent: "Techizh and HOXBI are technology service partners that provide extended product development lifecycle solutions from concept, engineering design, and lean manufacturing, to prototyping, product testing, and logistics.",
        cardFlagSecLinkText: "hoxbi.pk",
        cardFlagSecLink: "/",
    },
    {
        id: 4,
        cardFlagSecImg: USA,
        cardFlagSecHeading: "USA",
        cardFlagSecHeadingTwo: "LAS VEGAS",
        cardFlagSecContent: "Techizh collaborates with CCCCCC to assist them in Engineering Services such as PCB Design, Fabrication, and Testing. We work together and bring about high-quality, scalable, and customizable solutions.",
        cardFlagSecLinkText: "cccccc.com",
        cardFlagSecLink: "cardFlagSecLinkText",
    },
    {
        id: 5,
        cardFlagSecImg: USA,
        cardFlagSecHeading: "USA",
        cardFlagSecHeadingTwo: "Rochester",
        cardFlagSecContent: "Techizh and EEEEEEEEEEEE together create innovative solutions for IOT, Rail industry and Remote Monitoring. We help customers maximize existing software investments and embrace innovation in a world of hybrid IT.",
        cardFlagSecLinkText: "eeeeeeeeeeee.com",
        cardFlagSecLink: "/",
    },
]

const HoverImg = [
    {
        id: 1,
        hoverImgItem: OXY,
    },
    {
        id: 2,
        hoverImgItem: ABTC,
    },
    {
        id: 3,
        hoverImgItem: DXC,
    },
    {
        id: 4,
        hoverImgItem: TechCo,
    },
    {
        id: 5,
        hoverImgItem: Blog,
    },
    {
        id: 6,
        hoverImgItem: Loom,
    },
    {
        id: 7,
        hoverImgItem: SilkRoad,
    },
    {
        id: 8,
        hoverImgItem: CBC,
    },
    {
        id: 9,
        hoverImgItem: KPI,
    },
]

const ListItems = [
    {
        id: 1,
        listItemsContent: "Deep expertise in a wide range of technologies whether they are current or emerging, and staying up-to-date on the latest industry trends to deliver real business value.",
    },
    {
        id: 2,
        listItemsContent: "Passionate team with a blend of experienced professionals, and energetic fresh graduates with 2 to 3 years of experience, empowered to work on what they love.",
    },
    {
        id: 3,
        listItemsContent: "High retention rate of world-class engineers that translates into knowledge retention and our clients reap the rewards of their investment for many years.",
    },
    {
        id: 4,
        listItemsContent: "Excellent client relationship management guaranteeing longer business relations and strong referrals as a trusted partner for businesses of all sizes.",
    },
    {
        id: 5,
        listItemsContent: "Strong emphasis on collaboration with clients, other industry experts and vendors to ensure that clients can stay ahead of the competition.",
    },
]

const ListItems1 = [
    {
        id: 1,
        listItemsContent: "Competitive and transparent pricing model without compromising on quality, to ensure our clients receive the best possible value for their investment.",
    },
    {
        id: 2,
        listItemsContent: "Ability to see challenges holistically about our clients’ business, their industries, and the regulating bodies of those industries, to provide sustainable solutions to these challenges.",
    },
    {
        id: 3,
        listItemsContent: "Consistent quality delivery, ongoing support and maintenance to ensure that our solutions continue to perform at their best.",
    },
    {
        id: 4,
        listItemsContent: "Exceptional customer service throughout every phase of the project, and we are always available to answer their questions and address their concerns.",
    },
]

const OurExpertices = [
    {
        id: 1,
        upperHeadingCon: "01",
        mainHeadingCon: "Data Science",
        contentPara: (<p>We use advanced statistical models, analytics and machine learning algorithms to uncover, unlock and extract valuable insights and opportunities from your complex data sets that drive business growth. Our data science team specializes in the followings to help organizations make data-driven decisions.</p>),
        unorderlistItem: (<ul>
            <li>➤ Big Data Management</li>
            <li>➤ Big Data Processing and Storage</li>
            <li>➤ Solutions</li>
            <li>➤ Data Collection</li>
            <li>➤ Data Cleaning</li>
            <li>➤ Data Transformation</li>
            <li>➤ Data Visualization</li>
            <li>➤ Data Analysis</li>
            <li>➤ Data Visualization</li>
            <li>➤ Data Mining</li>
            <li>➤ Data Extraction </li>
            <li>➤ Data Interpretation.</li>
        </ul>),
        imgExpertices: ItemOne,
        itemId:"dataScience"
    },
    {
        id: 2,
        upperHeadingCon: "02",
        mainHeadingCon: "Artificial Intelligence",
        contentPara: (<><p>We keep eye on the latest advancements to build custom AI solutions (predictive intelligent systems) for businesses that can reason, learn, and interact like humans but in a non-traditional way. For data-driven decisions, automating processes and improving business operations, our AI team leverages the following expertise to help businesses automate routine tasks, streamline operations, optimize workflows, and improve customer experiences.</p></>),
        unorderlistItem: (<ul>
            <li>➤ Chatbot and Virtual Assistant Development</li>
            <li>➤ Predictive Analytics, Predictive Modeling and Predictive Maintenance</li>
            <li>➤ Machine Learning Algorithms Development and Implementation</li>
            <li>➤ Machine Learning Model Development, Deep Learning & Reinforcement Learning</li>
            <li>➤ Intelligent Automation</li>
            <li>➤ Computer Vision, Image Recognition and Speech Recognition</li>
            <li>➤ Natural Language Processing (NLP)</li>
            <li>➤ Robotic Process Automation (RPA)</li>
            <li>➤ Recommendation Systems</li>
            <li>➤  Anomaly Detection and Prevention</li>
        </ul>),
        imgExpertices: ItemTwo,
        itemId:"artificialIntelligence"
    },
    {
        id: 3,
        upperHeadingCon: "03",
        mainHeadingCon: "Web Applications",
        contentPara: (<><p>Our web applications team designs and develops high-quality web applications that meet the unique needs of each client we work with. We have extensive experience in a variety of modern web technologies to build custom, scalable and secure web applications that deliver an intuitively seamless, functional and aesthetically pleasing user experience. Our web application experience includes:</p></>),
        unorderlistItem: (<ul>
            <li>➤Web Design and UX/UI Design</li>
            <li>➤Web App Development</li>
            <li>➤E-commerce Website Development</li>
            <li>➤Custom Web Software Development</li>
            <li>➤Web Application Testing and Validation</li>
            <li>➤Web Hosting and Server Maintenance</li>
            <li>➤Web Analytics and Optimization</li>
        </ul>),
        imgExpertices: ItemTwelve,
        itemId:"webApplications"
    },
    {
        id: 4,
        upperHeadingCon: "04",
        mainHeadingCon: "Mobile Applications",
        contentPara: (<><p>Our mobile application developers specialize in developing high-quality, engaging mobile applications for both iOS and Android devices. We work closely with businesses to understand their requirements, needs and goals, and we use the latest mobile technologies to provide solutions and deliver tangible business results with seamless user experience that stand out in a crowded marketplace. Our mobile application services include:</p></>),
        unorderlistItem: (<ul>
            <li>➤ iOS and Android App Development</li>
            <li>➤ Hybrid App Development using frameworks like React Native & Flutter</li>
            <li>➤ Mobile App UX and UI Design</li>
            <li>➤ App Testing and Debugging</li>
            <li>➤ App Maintenance and Support</li>
            <li>➤ App Store Optimization and Marketing</li>
        </ul>),
        imgExpertices: ItemEleven,
        itemId:"mobileApplications"
    },
    {
        id: 5,
        upperHeadingCon: "05",
        mainHeadingCon: "Blockchain",
        contentPara: (<><h5>Accelerate digital innovation journey with Blockchain solutions</h5><p>We help organizations boost efficiency, agility and security through custom blockchain development services. We meet business leaders to consult and accelerate their initiatives by blending our extensive experience with innovation, enabling them to successfully navigate the transition towards Web 3.0 and beyond.</p></>),
        unorderlistItem: (<ul>
            <li>➤ As a custom blockchain development company, we help companies set up Blockchain networks for their business and create secure decentralized databases to help store data, manage supply chains, handle transactions, or any other business needs that Blockchain can solve without jeopardizing privacy.</li>
            <li>➤ We deliver all types of smart contracts that guarantee the integrity of multi- party agreements and automatically enforce fixed obligations. To make sure there are no flaws, misbehaviors or security holes in the smart contract code, we perform full-scale pre-release functional and non-functional testing.</li>
            <li>➤ We provide encrypted peer-to-peer apps with no single point of failure and zero downtime. Dapps combine a number of interrelated smart contracts, united by a business logic and frontend. Our team builds complete Dapp architecture, including communication with oracles (data feeds), and creates a comprehensive UX/UI design.</li>
            <li>➤ With extensive experience in blockchain and fintech, our team is well- equipped to develop decentralized FinTech solutions from scratch, enabling digital transactions between multiple parties. Our DeFi blockchain development service providers harness automated market makers, staking pools, governance tokens and self-executing smart contracts for maximum transparency and autonomy.</li>
            <li>➤ Our comprehensive Web3 Cybersecurity and Smart Contract Audit Services help companies launch and maintain complex blockchain applications. Deploy your smart applications with the assurance that your assets are completely shielded from malicious attacks. We help identify vulnerabilities, exploit vectors, and risk exposures across all blockchain solutions.</li>
        </ul>),
        imgExpertices: ItemEleven,
        itemId:"blockchain"
    },
    {
        id: 6,
        upperHeadingCon: "06",
        mainHeadingCon: "Cybersecurity",
        contentPara: (<><p>Our cybersecurity experts work tirelessly to help businesses protect their critical data, valuable assets and systems from cyber threats by designing and implementing comprehensive security solutions. We offer a range of services to help businesses identify and mitigate security risks, and ensure compliance with industry regulations.</p></>),
        unorderlistItem: (<ul>
            <li>➤ Network Security and Monitoring</li>
            <li>➤ Vulnerability Assessment</li>
            <li>➤ Penetration Testing</li>
            <li>➤ Threat Detection and Response</li>
            <li>➤ Security Compliance</li>
            <li>➤ Application Security Testing and Code Review</li>
            <li>➤ Risk Assessment and Management</li>
            <li>➤ Identity and Access Management</li>
            <li>➤ Incident Response and Disaster Recovery</li>
            <li>➤ Cloud Security and Compliance</li>
        </ul>),
        imgExpertices: ItemFive,
        itemId:"cyberSecurity"
    },
    {
        id: 7,
        upperHeadingCon: "07",
        mainHeadingCon: "Quality Assurance",
        contentPara: (<><p>Our QA experts are committed that every solution we provide meets the quality and reliability requirements. We follow a rigorous testing process to ensure that our solutions meet the unique needs of each client we work with, and perform as expected or beyond that. Our QA expertise include:</p></>),
        unorderlistItem: (<ul>
            <li>➤ Functional Testing, Performance Testing, Load Testing, Security Testing and Test Automation</li>
            <li>➤ Quality Control and Assurance</li>
            <li>➤ Processes Development and Optimization</li>
            <li>➤ Quality Management</li>
            <li>➤ Software Auditing</li>
            <li>➤ Continuous Integration and Continuous Delivery (CI/CD)</li>
            <li>➤ User Acceptance Testing (UAT)</li>
        </ul>),
        imgExpertices: ItemEight,
        itemId:"qualityAssurance"
    },
]

const FaqsData = [
    {
        id: 1,
        faqItemUpperHeading:"03",
        faqItemLowerHeading: "Privacy and Data Protection",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 2,
        faqItemUpperHeading:"04",
        faqItemLowerHeading: "Confidentiality",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 3,
        faqItemUpperHeading:"05",
        faqItemLowerHeading: "Fees and Payment",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 4,
        faqItemUpperHeading:"06",
        faqItemLowerHeading: "Liability & Disclaimers",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 5,
        faqItemUpperHeading:"07",
        faqItemLowerHeading: "Limitations of Liability",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 6,
        faqItemUpperHeading:"08",
        faqItemLowerHeading: "Termination & Suspension",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 7,
        faqItemUpperHeading:"09",
        faqItemLowerHeading: "Termination",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 8,
        faqItemUpperHeading:"10",
        faqItemLowerHeading: "Governing Law & Jurisdiction",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 9,
        faqItemUpperHeading:"11",
        faqItemLowerHeading: "Governing Law",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 10,
        faqItemUpperHeading:"12",
        faqItemLowerHeading: "Dispute Resolution",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 11,
        faqItemUpperHeading:"13",
        faqItemLowerHeading: "Indemnification",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 12,
        faqItemUpperHeading:"14",
        faqItemLowerHeading: "Modifications to the Terms",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
]

const FaqsData1 = [
    {
        id: 13,
        faqItemUpperHeading:"15",
        faqItemLowerHeading: "Entire Agreement",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 14,
        faqItemUpperHeading:"16",
        faqItemLowerHeading: "Waiver",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 15,
        faqItemUpperHeading:"17",
        faqItemLowerHeading: "Severability",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 16,
        faqItemUpperHeading:"18",
        faqItemLowerHeading: "Assignment",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 17,
        faqItemUpperHeading:"19",
        faqItemLowerHeading: "Account Registration",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 18,
        faqItemUpperHeading:"20",
        faqItemLowerHeading: "Payment",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 19,
        faqItemUpperHeading:"21",
        faqItemLowerHeading: "Warranties",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 20,
        faqItemUpperHeading:"22",
        faqItemLowerHeading: "Disclaimer of Warranties",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 21,
        faqItemUpperHeading:"23",
        faqItemLowerHeading: "Disclaimer",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 22,
        faqItemUpperHeading:"24",
        faqItemLowerHeading: "Feedback",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 23,
        faqItemUpperHeading:"25",
        faqItemLowerHeading: "Third-Party Content",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 24,
        faqItemUpperHeading:"26",
        faqItemLowerHeading: "Miscellaneous",
        faqItemContent:( 
            <> 
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
]

const SliderData = [
    {
        id:1,
        sliderImg: SliderItem1,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem1,
    },
    {
        id:2,
        sliderImg: SliderItem2,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem2,
    },
    {
        id:3,
        sliderImg: SliderItem3,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem3,
    },
    {
        id:4,
        sliderImg: SliderItem4,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem4,
    },
    {
        id:5,
        sliderImg: SliderItem5,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem5,
    },
    {
        id:6,
        sliderImg: SliderItem6,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem6,
    },
    {
        id:7,
        sliderImg: SliderItem7,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem7,
    },
    {
        id:8,
        sliderImg: SliderItem8,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem8,
    },
    {
        id:9,
        sliderImg: SliderItem1,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem1,
    },
    {
        id:10,
        sliderImg: SliderItem2,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem2,
    },
    {
        id:11,
        sliderImg: SliderItem3,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem3,
    },
    {
        id:12,
        sliderImg: SliderItem4,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem4,
    },
    {
        id:13,
        sliderImg: SliderItem5,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem5,
    },
    {
        id:14,
        sliderImg: SliderItem6,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem6,
    },
    {
        id:15,
        sliderImg: SliderItem7,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem7,
    },
    {
        id:16,
        sliderImg: SliderItem8,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail:(
            <> 
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem8,
    },
]

const ProjectCardData = [
    {
        id: 1,
        projectCardLink: '',
        projectCardImg: Website1,
        projectCardHeading: 'Websites',
        projectCardDesc: 'Websites',
    },
    {
        id: 2,
        projectCardLink: '',
        projectCardImg: Website2,
        projectCardHeading: 'Websites',
        projectCardDesc: 'Websites',
    },
    {
        id: 3,
        projectCardLink: '',
        projectCardImg: Website3,
        projectCardHeading: 'Websites',
        projectCardDesc: 'Websites',
    },
    {
        id: 4,
        projectCardLink: '',
        projectCardImg: Website4,
        projectCardHeading: 'Websites',
        projectCardDesc: 'Websites',
    },
    {
        id: 5,
        projectCardLink: '',
        projectCardImg: Website5,
        projectCardHeading: 'Websites',
        projectCardDesc: 'Websites',
    },
    {
        id: 11,
        projectCardLink: '',
        projectCardImg: Website6,
        projectCardHeading: 'Websites',
        projectCardDesc: 'Websites',
    },
    {
        id: 12,
        projectCardLink: '',
        projectCardImg: Website7,
        projectCardHeading: 'Websites',
        projectCardDesc: 'Websites',
    },
    {
        id: 13,
        projectCardLink: '',
        projectCardImg: Website8,
        projectCardHeading: 'Websites',
        projectCardDesc: 'Websites',
    },
    {
        id: 14,
        projectCardLink: '',
        projectCardImg: WebApp1,
        projectCardHeading: 'Web Application',
        projectCardDesc: 'Web Application',
    },
    {
        id: 15,
        projectCardLink: '',
        projectCardImg: WebApp2,
        projectCardHeading: 'Web Application',
        projectCardDesc: 'Web Application',
    },
    {
        id: 16,
        projectCardLink: '',
        projectCardImg: WebApp3,
        projectCardHeading: 'Web Application',
        projectCardDesc: 'Web Application',
    },
    {
        id: 17,
        projectCardLink: '',
        projectCardImg: WebApp4,
        projectCardHeading: 'Web Application',
        projectCardDesc: 'Web Application',
    },
    {
        id: 18,
        projectCardLink: '',
        projectCardImg: WebApp5,
        projectCardHeading: 'Web Application',
        projectCardDesc: 'Web Application',
    },
    {
        id: 19,
        projectCardLink: '',
        projectCardImg: WebApp6,
        projectCardHeading: 'Web Application',
        projectCardDesc: 'Web Application',
    },
    {
        id: 20,
        projectCardLink: '',
        projectCardImg: WebApp7,
        projectCardHeading: 'Web Application',
        projectCardDesc: 'Web Application',
    },
    {
        id: 21,
        projectCardLink: '',
        projectCardImg: WebApp8,
        projectCardHeading: 'Web Application',
        projectCardDesc: 'Web Application',
    },
    {
        id: 22,
        projectCardLink: '',
        projectCardImg: MobileApp1,
        projectCardHeading: 'Mobile Application',
        projectCardDesc: 'Mobile Application',
    },
    {
        id: 23,
        projectCardLink: '',
        projectCardImg: MobileApp2,
        projectCardHeading: 'Mobile Application',
        projectCardDesc: 'Mobile Application',
    },
    {
        id: 24,
        projectCardLink: '',
        projectCardImg: MobileApp3,
        projectCardHeading: 'Mobile Application',
        projectCardDesc: 'Mobile Application',
    },
    {
        id: 25,
        projectCardLink: '',
        projectCardImg: MobileApp4,
        projectCardHeading: 'Mobile Application',
        projectCardDesc: 'Mobile Application',
    },
    {
        id: 26,
        projectCardLink: '',
        projectCardImg: MobileApp5,
        projectCardHeading: 'Mobile Application',
        projectCardDesc: 'Mobile Application',
    },
    {
        id: 27,
        projectCardLink: '',
        projectCardImg: MobileApp6,
        projectCardHeading: 'Mobile Application',
        projectCardDesc: 'Mobile Application',
    },
    {
        id: 28,
        projectCardLink: '',
        projectCardImg: MobileApp7,
        projectCardHeading: 'Mobile Application',
        projectCardDesc: 'Mobile Application',
    },
    {
        id: 29,
        projectCardLink: '',
        projectCardImg: MobileApp8,
        projectCardHeading: 'Mobile Application',
        projectCardDesc: 'Mobile Application',
    },
    {
        id: 30,
        projectCardLink: '',
        projectCardImg: UIUX1,
        projectCardHeading: 'UI/UX',
        projectCardDesc: 'UI/UX',
    },
    {
        id: 31,
        projectCardLink: '',
        projectCardImg: UIUX2,
        projectCardHeading: 'UI/UX',
        projectCardDesc: 'UI/UX',
    },
    {
        id: 32,
        projectCardLink: '',
        projectCardImg: UIUX3,
        projectCardHeading: 'UI/UX',
        projectCardDesc: 'UI/UX',
    },
    {
        id: 33,
        projectCardLink: '',
        projectCardImg: UIUX4,
        projectCardHeading: 'UI/UX',
        projectCardDesc: 'UI/UX',
    },
    {
        id: 34,
        projectCardLink: '',
        projectCardImg: UIUX5,
        projectCardHeading: 'UI/UX',
        projectCardDesc: 'UI/UX',
    },
    {
        id: 35,
        projectCardLink: '',
        projectCardImg: UIUX6,
        projectCardHeading: 'UI/UX',
        projectCardDesc: 'UI/UX',
    },
    {
        id: 36,
        projectCardLink: '',
        projectCardImg: UIUX7,
        projectCardHeading: 'UI/UX',
        projectCardDesc: 'UI/UX',
    },
    {
        id: 37,
        projectCardLink: '',
        projectCardImg: UIUX8,
        projectCardHeading: 'UI/UX',
        projectCardDesc: 'UI/UX',
    },
    {
        id: 38,
        projectCardLink: '',
        projectCardImg: DataScience1,
        projectCardHeading: 'Data Science',
        projectCardDesc: 'Data Science',
    },
    {
        id: 39,
        projectCardLink: '',
        projectCardImg: DataScience2,
        projectCardHeading: 'Data Science',
        projectCardDesc: 'Data Science',
    },
    {
        id: 40,
        projectCardLink: '',
        projectCardImg: DataScience3,
        projectCardHeading: 'Data Science',
        projectCardDesc: 'Data Science',
    },
    {
        id: 41,
        projectCardLink: '',
        projectCardImg: DataScience4,
        projectCardHeading: 'Data Science',
        projectCardDesc: 'Data Science',
    },
    {
        id: 42,
        projectCardLink: '',
        projectCardImg: DataScience5,
        projectCardHeading: 'Data Science',
        projectCardDesc: 'Data Science',
    },
    {
        id: 43,
        projectCardLink: '',
        projectCardImg: DataScience6,
        projectCardHeading: 'Data Science',
        projectCardDesc: 'Data Science',
    },
    {
        id: 44,
        projectCardLink: '',
        projectCardImg: DataScience7,
        projectCardHeading: 'Data Science',
        projectCardDesc: 'Data Science',
    },
    {
        id: 45,
        projectCardLink: '',
        projectCardImg: DataScience8,
        projectCardHeading: 'Data Science',
        projectCardDesc: 'Data Science',
    },
]

const stats = [
    { 
        value: 1789, 
        label: "HAPPY CLIENTS"
    },
    { 
        value: 419, 
        label: "FINISHED PROJECTS"
    },
    { 
        value: 245, 
        label: "SKILLED EXPERTS"
    },
    { 
        value: 416, 
        label: "HONORABLE AWARDS"
    },
]

export {
    CardSecData,
    CardFlagData,
    HoverImg,
    ListItems,
    ListItems1,
    OurExpertices,
    FaqsData,
    FaqsData1,
    SliderData,
    ProjectCardData,
    stats,
}