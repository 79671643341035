import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { Close, Logo, Menu } from '../img/AllImages'

const Header = () => {

    const [showMenu, setShowMenu] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    const location = useLocation();
    const currentUrl = location.pathname;

    const menuHandler = () => {
        setShowMenu(!showMenu)
    }

    const dropdownShowMenu = () => {
        setShowDropdown(!showDropdown)
    }

    return (
        <>
            <header>
                <Container>
                    <Row>
                        <Col md={6} xs={6}>
                            <div className="logo">
                                <Link to="/"><img src={Logo} alt="" /></Link>
                            </div>
                        </Col>
                        <Col md={6} className='align-self-center d-none d-md-block'>
                            <nav className='menuLink'>
                                <ul>
                                    <li className={currentUrl === "/" ? "activeLink" : ""}><Link to="/">Home</Link></li>
                                    <li className={currentUrl === "/service" ? "activeLink" : ""}><Link to="/service#dataScience">Services</Link></li>
                                    {/* <li className={currentUrl === "/company" ? "activeLink showDropdown" : ""}><Link to="/company">Company</Link></li> */}
                                    <li className={currentUrl === "/about-us" ? "activeLink showDropdown" : (currentUrl === "/synergistic-relationships" ? "activeLink showDropdown" : (currentUrl === "/corporate-social-responsibility" ? "activeLink showDropdown" : (currentUrl === "/our-team" ? "activeLink showDropdown" : (currentUrl === "/technology-partners" ? "activeLink showDropdown" : (currentUrl === "/global-corporate-alliances" ? "activeLink showDropdown" : "showDropdown")))))}>
                                        <span>Company</span>
                                        <div className="dropdown">
                                            <ul>
                                                <li><Link className={currentUrl === "/about-us" ? "activeLinkDropDown" : ""} to="/about-us">About Us</Link></li>
                                                <li><Link className={currentUrl === "/synergistic-relationships" ? "activeLinkDropDown" : ""} to="/synergistic-relationships">Synergistic Relationships</Link></li>
                                                <li><Link className={currentUrl === "/corporate-social-responsibility" ? "activeLinkDropDown" : ""} to="/corporate-social-responsibility">Corporate Social Responsibility</Link></li>
                                                <li><Link className={currentUrl === "/our-team" ? "activeLinkDropDown" : ""} to="/our-team">Certifications</Link></li>
                                                <li><Link className={currentUrl === "/technology-partners" ? "activeLinkDropDown" : ""} to="/technology-partners">Technology Partners</Link></li>
                                                <li><Link className={currentUrl === "/global-corporate-alliances" ? "activeLinkDropDown" : ""} to="/global-corporate-alliances">Global Corporate Alliances</Link></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className={currentUrl === "/contact-us" ? "activeLink" : ""}><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                            </nav>
                        </Col>
                        <Col md={6} xs={6} className='d-flex d-md-none justify-content-end align-items-center'>
                            <img src={Menu} alt="" onClick={menuHandler} />
                        </Col>
                    </Row>
                </Container>
            </header>
            {showMenu === true ? (
                <div className="sideMenuLayer">
                    <div className="sideMenu">
                        <div className="sideMenuLogo">
                            <img src={Logo} alt="" />
                            <img src={Close} alt="" onClick={menuHandler} />
                        </div>
                        <div className="sideMenuLink">
                            <nav>
                                <ul>
                                    <li className={currentUrl === "/" ? "activeLink" : ""}><Link to="/">Home</Link></li>
                                    <li className={currentUrl === "/services" ? "activeLink" : ""}><Link to="/services">Services</Link></li>
                                    <li className={currentUrl === "/about-us" ? "activeLinkMenu" : (currentUrl === "/synergistic-relationships" ? "activeLinkMenu" : (currentUrl === "/corporate-social-responsibility" ? "activeLinkMenu" : (currentUrl === "/our-team" ? "activeLinkMenu" : (currentUrl === "/technology-partners" ? "activeLinkMenu" : (currentUrl === "/global-corporate-alliances" ? "activeLinkMenu" : "showDropdown")))))} onClick={dropdownShowMenu}>
                                        <span>Company</span>
                                        {showDropdown === true ? (
                                            <div className="dropdownMenu">
                                                <ul>
                                                    <li><Link className={currentUrl === "/about-us" ? "activeLinkDropDown" : ""} to="/about-us">About Us</Link></li>
                                                    <li><Link className={currentUrl === "/synergistic-relationships" ? "activeLinkDropDown" : ""} to="/synergistic-relationships">Synergistic Relationships</Link></li>
                                                    <li><Link className={currentUrl === "/corporate-social-responsibility" ? "activeLinkDropDown" : ""} to="/corporate-social-responsibility">Corporate Social Responsibility</Link></li>
                                                    <li><Link className={currentUrl === "/our-team" ? "activeLinkDropDown" : ""} to="/our-team">Certifications</Link></li>
                                                    <li><Link className={currentUrl === "/technology-partners" ? "activeLinkDropDown" : ""} to="/technology-partners">Technology Partners</Link></li>
                                                    <li><Link className={currentUrl === "/global-corporate-alliances" ? "activeLinkDropDown" : ""} to="/global-corporate-alliances">Global Corporate Alliances</Link></li>
                                                </ul>
                                            </div>
                                        ) : null}
                                    </li>
                                    <li className={currentUrl === "/contact-us" ? "activeLink" : ""}><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            ) : ""}
        </>
    )
}

export default Header