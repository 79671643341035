import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FaceBook, FooterLogo, Insta, Linkedin, Location, Phone, Tweeter } from '../img/AllImages';

const Footer = () => {
    const location = useLocation();
    // const history = useHistory();

    // useEffect(() => {
    //     if (location.hash) {
    //         console.log(document.getElementsByName('li').dataset.id);
    //         const section = document.querySelector(location.hash);
    //         if (section) {
    //             section.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     } else if (location.pathname === '/service') {
    //         const sectionId = location.hash.replace("#", "");
    //         const section = document.getElementsByName('li').dataset.id;
    //         if (section) {
    //             section.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    // }, [location]);

    // const handleClick = (e) => {
    //     if (location.pathname === '/service') {
    //         e.preventDefault();
    //         const target = e.target.getAttribute('href');
    //         history.push(target);
    //         const sectionId = target.substring(target.indexOf('#') + 1);
    //         const section = document.getElementById(sectionId);
    //         if (section) {
    //             section.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    // };

    return (
        <>
            <footer>
                <Container>
                    <Row>
                        <Col md={12} xl={5} className="mb-5 mb-xl-0">
                            <div className="footerLogo">
                                <img src={FooterLogo} alt="" />
                            </div>
                            <div className="footerLinkIcon d-none d-md-block">
                                <ul>
                                    <li><Link to="/"><img src={FaceBook} alt="" /></Link></li>
                                    <li><Link to="/"><img src={Tweeter} alt="" /></Link></li>
                                    <li><Link to="/"><img src={Insta} alt="" /></Link></li>
                                    <li><Link to="/"><img src={Linkedin} alt="" /></Link></li>
                                </ul>
                            </div>
                            <div className="footerLink d-none d-md-block">
                                <div className="footerLinkHeading"></div>
                                <ul>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms-of-services">Terms of Service</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                    <li><div className="copyRight"><span> Copyright © TECHIZH | All Rights Reserved </span></div></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} xl={3} className="mb-4 mb-md-0">
                            <div className="footerLink">
                                <div className="footerLinkHeading">
                                    <h5>Company</h5>
                                </div>
                                <ul>
                                    <li><Link to="/about-us">About us</Link></li>
                                    <li><Link to="/synergistic-relationships">Synergistic Relationships</Link></li>
                                    <li><Link to="/corporate-social-responsibility">Corporate Social Responsibility</Link></li>
                                    <li><Link to="/our-team">Certifications</Link></li>
                                    <li><Link to="/technology-partners">Technology Partners</Link></li>
                                    <li><Link to="/global-corporate-alliances">Global Corporate Alliances</Link></li>
                                </ul>
                            </div>
                            <div className="footerLink">
                                <div className="footerLinkHeading">
                                    <h5>Key Services</h5>
                                </div>
                                <ul>
                                    <li><Link to="/service#dataScience">Data Science</Link></li>
                                    <li><Link to="/service#artificialIntelligence">Artificial Intelligence</Link></li>
                                    <li><Link to="/service#internetofThings">Internet of Things (IoT)</Link></li>
                                    <li><Link to="/service#hardwareEngineering">Hardware Engineering</Link></li>
                                    <li><Link to="/service">View All Services</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} xl={3} className="footerLocation">
                            <div className="footerLink">
                                <div className="footerLinkHeading">
                                    <h5>Contact Information</h5>
                                </div>
                                <div className="canadaLocation">
                                    <div className="canadaLocationHeading">
                                        <h6>Canada</h6>
                                    </div>
                                    <div className="canadaLocationDetail">
                                        <div className="canadaLocationDetailItem">
                                            <span>
                                                <img src={Location} alt="" />
                                            </span>
                                            <span> <a href="https://maps.google.com?q=123+Main+Street+Suite+500+Anytown+Canada+12345" target="_blank" rel="noopener noreferrer">
                                                123 Main Street Suite 500 Anytown, Canada 12345
                                            </a> </span>
                                        </div>
                                        <div className="canadaLocationDetailItem">
                                            <span>
                                                <img src={Phone} alt="" />
                                            </span>
                                            <span><a href="tel:+15555555555">+1 (555) 555-5555</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="canadaLocation">
                                    <div className="canadaLocationHeading">
                                        <h6>USA</h6>
                                    </div>
                                    <div className="canadaLocationDetail">
                                        <div className="canadaLocationDetailItem">
                                            <span>
                                                <img src={Location} alt="" />
                                            </span>
                                            <span> <a href="https://maps.google.com?q=123+Main+Street+Suite+500+Anytown+USA+12345" target="_blank" rel="noopener noreferrer">
                                                123 Main Street Suite 500 Anytown, USA 12345
                                            </a> </span>
                                        </div>
                                        <div className="canadaLocationDetailItem">
                                            <span>
                                                <img src={Phone} alt="" />
                                            </span>
                                            <span><a href="tel:+15555555555">+1 (555) 555-5555</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="canadaLocation">
                                    <div className="canadaLocationHeading">
                                        <h6>Pakistan</h6>
                                    </div>
                                    <div className="canadaLocationDetail">
                                        <div className="canadaLocationDetailItem">
                                            <span>
                                                <img src={Location} alt="" />
                                            </span>
                                            <span> <a href="https://maps.google.com?q=123+Main+Street+Suite+500+Anytown+Pakistan+12345" target="_blank" rel="noopener noreferrer">
                                                123 Main Street Suite 500 Anytown, Pakistan 12345
                                            </a> </span>
                                        </div>
                                        <div className="canadaLocationDetailItem">
                                            <span>
                                                <img src={Phone} alt="" />
                                            </span>
                                            <span><a href="tel:+925555555555">+92 (555) 555-5555</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} xl={5} className="d-block d-md-none mt-5">
                            <div className="footerLink">
                                <div className="footerLinkHeading"></div>
                                <ul>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms-of-services">Terms of Service</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                                <div className="footerLinkIcon">
                                    <ul>
                                        <li><Link to="/"><img src={FaceBook} alt="" /></Link></li>
                                        <li><Link to="/"><img src={Tweeter} alt="" /></Link></li>
                                        <li><Link to="/"><img src={Insta} alt="" /></Link></li>
                                        <li><Link to="/"><img src={Linkedin} alt="" /></Link></li>
                                    </ul>
                                </div>
                                <ul>
                                    <li>
                                        <div className="copyRight">
                                            <span> Copyright © TECHIZH | All Rights Reserved</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default Footer;
