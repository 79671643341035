import React from 'react'
import { FaqsCom, Headings, TwoHeadingCom } from '../../Components/Component'
import { SectionThirteen } from '../../img/AllImages'
import { Col, Container, Row } from 'react-bootstrap'

const TermsOfServiceSec = () => {
    return (
        <>
            <section className='termsCondition'>
                <Headings
                    UpperHeading="User Agreement"
                    LowerHeading="TERMS OF SERVICE"
                    HeadingContent='Welcome to Techizh! These Terms of Service (the "Terms") govern your access to and use of our services, including our website, software, applications, platforms, tools, and technologies (collectively, the "Services"). By using our Services, you agree to be bound by these Terms, our Privacy Policy, and any other applicable policies, guidelines, or agreements that we may provide to you from time to time. If you do not agree to these Terms or our Privacy Policy, you may not use our Services.'
                />
            </section>

            <section className='privacyPolicySetting termsOfServicesSetting'>
                <Container>
                    <Row className='flex-md-row-reverse flex-column-reverse'>
                        <TwoHeadingCom
                            TwoHeadingUpper="01"
                            TwoHeadingContent={
                                <>
                                    <div className="twoHeadingItem">
                                        <div className="secHeading">
                                            <h3>User Obligations</h3>
                                        </div>
                                        <div className="secContent">
                                            <p>You agree to use our Services only for lawful and legitimate purposes in accordance with these Terms, and to comply with all applicable laws and regulations. You shall not use our Services for any illegal or harmful activities, such as infringing on intellectual property rights, distributing malware or spam, or harassing or defaming others. You are solely responsible for any content or information that you submit, upload, or publish through our Services, and you shall not post or share any content that is false, misleading, or offensive. You may not use our Services or You shall not make use of our Services:</p>
                                            <ul>
                                                <li><span>➤ </span> In any way that violates any applicable federal, state, local, or international law or regulation.</li>
                                                <li><span>➤ </span> For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</li>
                                                <li><span>➤ </span> To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
                                                <li><span>➤ </span> To impersonate or attempt to impersonate Techizh, a Techizh employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).</li>
                                                <li><span>➤ </span> To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of our Services, or which, as determined by us, may harm Techizh or users of our Services or expose them to liability.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div className="secContent">
                                        <p>Techizh is a technology consulting and service providing firm that uses technology to enrich people’s lives, drive business growth and create value for our clients. With a team of experienced data scientists, engineers, software developers, IT specialists, and AI experts, we are passionate to offer data insights, technology excellence, efficient management, business intelligence, industry knowledge, aesthetics, and vision-all joined together-in one neat package.</p>
                                        <p>We as Techizh are committed to maximize your potential with our expertise in Embedded Systems, Custom Hardware Design, Systems Engineering, Cybersecurity, IoT, Electronics and Communication, Data Science, Machine Learning, Artificial Intelligence, Web Applications, Mobile Applications, Desktop Applications, and end-to-end automation.</p>
                                    </div> */}
                                </>
                            }
                            TwoHeadingContentImg={
                                <>
                                    <div className="twoHeadingImg text-end secImg">
                                        <img src={SectionThirteen} alt="" />
                                    </div>
                                </>
                            }
                        />
                    </Row>
                </Container>
            </section>

            <section className='privacyPolicySetting termsOfServicesSetting'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="contentSecT">
                                <div className="headings">
                                    <div className="upperHeading">
                                        <h3>02</h3>
                                    </div>
                                    <div className="lowerHeading">
                                        <h4>Intellectual Property</h4>
                                    </div>
                                    <div className="secContent">
                                        <p>All intellectual property rights related to our Services and their entire contents, features, and functionality, (including but not limited to all information, trademarks, copyrights, patents, trade secrets, other proprietary information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by Techizh or belong to us or our licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. You are granted a limited, non-exclusive, non-transferable license to use our Services for your personal or internal business purposes only. You shall not reproduce, distribute, modify, or create derivative works of our Services or any content without our prior written consent.</p>
                                        <p>These Terms permit you to use our Services for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Services, except as follows:</p>
                                        <ul>
                                            <li>➤ Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
                                            <li>➤ You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
                                            <li>➤ You may print or download one copy of a reasonable number of pages of the Services for your own personal, non-commercial use and not for further reproduction, publication, or distribution.</li>
                                            <li>➤ If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, and not for further reproduction, publication, or distribution. If you download or use any software from our Services, you agree that such software may be automatically updated and that the updates may be required for you to continue using the Services.</li>
                                        </ul>
                                        <p>If we provide social media features with certain content, you may take such actions as are enabled by such features. <br />
                                            You must not:</p>
                                        <ul className='bgItemChange'>
                                            <li><span>➤ </span> Modify copies of any materials from our Services.</li>
                                            <li><span>➤ </span> Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.</li>
                                            <li><span>➤ </span> Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from our Services.</li>
                                            <li><span>➤ </span> Access or use for any commercial purposes any part of our Services or any services or materials available through our Services.</li>
                                        </ul>
                                        <p>If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of our Services in breach of these Terms, your right to use our Services will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. Any unauthorized use may also violate applicable laws, including without limitation copyright and trademark laws and applicable communications regulations and statutes.</p>
                                        <p>We retain all rights, title, and interest in and to the Services, including all intellectual property rights. You acknowledge that the Services and any related software, data, and content are protected by copyright, trademark, and other laws. You agree not to reproduce, distribute, modify, or create derivative works of the Services, or any part thereof, without our prior written consent.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='termsOfServicesSetting'>
                <Container>
                    <Row className="faqRow">
                        <FaqsCom />
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default TermsOfServiceSec