import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Layout from './routes/Layout'
import { ChatWidget } from './Components/Component'

const App = () => {

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Layout />
      <ChatWidget />
    </>
  )
}

export default App