import React, { useEffect, useRef } from 'react'
import { FaqArrow, LogoBg, WhatsApp } from '../img/AllImages'
import { CardFlagData, CardSecData, FaqsData, FaqsData1, SliderData, stats } from '../Pages/Data/Data'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useState } from 'react'


import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import SwiperCore, { Navigation } from 'swiper/core';
import CountUp from 'react-countup'

SwiperCore.use([Navigation]);

const Button = ({ buttonText, BtnLink }) => {
    return (
        <>
            <Link to={BtnLink} className='button'>{buttonText}</Link>
        </>
    )
}

const Banner = ({ bannerHeading, bannerContent }) => {
    return (
        <>
            <div className="banner">
                <div className="bannerContent">
                    <div className="bannerHeading">
                        <h1>{bannerHeading}</h1>
                    </div>
                    <div className="bannerPara">
                        <p>{bannerContent}</p>
                    </div>
                    <div className="bannerBtn">
                        <Button BtnLink="/services" buttonText="View Services" />
                    </div>
                </div>
            </div>
        </>
    )
}

const Loading = () => {
    return (
        <div className="loadingStyled">
            <img src={LogoBg} alt="" />
        </div>
    )
}

const CardSec = () => {
    return (
        <>
            {CardSecData.map((item, key) => {
                return (
                    <Col md={6} sm={6} xs={12} key={key}>
                        <div className="cardSecitem">
                            <div className='cardSecImg'>
                                <img src={item.cardSecImg} alt="" />
                            </div>
                            <div className="cardSecBottom">
                                <div className="cardSecMiddle">
                                    <div className='cardSecIcon'>
                                        <img src={item.cardSecIcon} alt="" />
                                    </div>
                                    <div className='cardSecHeading'>
                                        <h4>{item.cardSecHeading}</h4>
                                    </div>
                                </div>
                                <div className='cardSecContent'>
                                    <p>{item.cardSecContent}</p>
                                </div>
                                <div className='cardSecLink'>
                                    <Link to={`/service#${item.cardSecLink}`}>Reade More</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                )
            })}
        </>
    )
}

const AboutSec = ({ UperHeading, Heading, Content, Img, ImgDiv, ContentDiv, ClassCl, ClassImg }) => {
    return (
        <>
            <Col md={ContentDiv} className={ClassCl}>
                <div className="headingContenr">
                    <div className="uperHeading">
                        <h2>{UperHeading}</h2>
                    </div>
                    <div className="heading">
                        <h3>{Heading}</h3>
                    </div>
                    <div className="content">
                        {Content}
                    </div>
                </div>
            </Col>
            <Col md={ImgDiv} className={ClassImg}>
                <div className="hoverSecImg secImg">
                    <img src={Img} alt="" />
                </div>
            </Col>
        </>
    )
}

// const Headings = ({ UpperHeading, LowerHeading, HeadingContent }) => {

//     // useEffect(() => {
//     //     window.addEventListener('scroll', handleScroll);
//     //     return () => {
//     //         window.removeEventListener('scroll', handleScroll);
//     //     };
//     // }, []);

//     // const handleScroll = () => {
//     //     const scrollTop = window.scrollY;
//     //     const upperHeading = document.querySelector('.upperHeading');
//     //     if (upperHeading) {
//     //         const scrollAmount = scrollTop / 0.7; // Adjust the scroll speed as needed
//     //         upperHeading.style.transform = `translateX(-${scrollAmount}px)`;
//     //     }
//     // };

//     const headingRef = useRef(null);

//     useEffect(() => {
//         const headingElement = headingRef.current;
//         if (headingElement) {
//             const hasOverflow = headingElement.scrollWidth > headingElement.clientWidth;
//             if (hasOverflow) {
//                 window.addEventListener('scroll', handleScroll);
//             }
//         }

//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);

//     const handleScroll = () => {
//         const scrollTop = window.scrollY;
//         const scrollAmount = scrollTop / 1.5; // Adjust the scroll speed as needed
//         headingRef.current.style.transform = `translateX(-${scrollAmount}px)`;
//     };

//     return (
//         <>
//             <div className="headings">
//                 <div className="upperHeading">
//                     <h1 ref={headingRef} style={{ transform: "translateX(0px)" }}>{UpperHeading}</h1>
//                 </div>
//                 <div className="lowerHeading">
//                     <h2>{LowerHeading}</h2>
//                 </div>
//                 <div className="headingContent">
//                     <p>{HeadingContent}</p>
//                 </div>
//             </div>
//         </>
//     )
// }

const Headings = ({ UpperHeading, LowerHeading, HeadingContent }) => {
    const headingRef = useRef(null);

    useEffect(() => {
        const headingElement = headingRef.current;
        let hasOverflow = false;

        const checkOverflow = () => {
            if (headingElement) {
                hasOverflow = headingElement.scrollWidth > headingElement.clientWidth;
            }

            if (hasOverflow) {
                window.addEventListener('scroll', handleScroll);
            } else {
                window.removeEventListener('scroll', handleScroll);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', checkOverflow);
        };
    }, []);

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        const headingElement = headingRef.current;

        if (headingElement) {
            const scrollAmount = scrollTop / 1.5; // Adjust the scroll speed as needed
            headingElement.style.transform = `translateX(-${scrollAmount}px)`;
        }
    };

    return (
        <div className="headings">
            <div className="upperHeading">
                <h1 ref={headingRef} style={{ transform: "translateX(0px)" }}>{UpperHeading}</h1>
            </div>
            <div className="lowerHeading">
                <h2>{LowerHeading}</h2>
            </div>
            <div className="headingContent">
                <p>{HeadingContent}</p>
            </div>
        </div>
    );
};

const CorporateCard = ({ corporateCardHeading, corporateCardContent }) => {
    return (
        <Col md={12} lg={6}>
            <div className="corporateCardStyle">
                <div className="corporateCardHeading">
                    <h5>{corporateCardHeading}</h5>
                </div>
                <div className="corporateCardContent">
                    <p>{corporateCardContent}</p>
                </div>
            </div>
        </Col>
    )
}

const SecImg = ({ secContentCol__, secImgCol__, secContent__, secContentClass__, secImgMain, secImgCol__Lg, secContentCol__Lg, secImgMainClass }) => {
    return (
        <>
            <Col md={secImgCol__} lg={secImgCol__Lg} className={secImgMainClass}>
                {secImgMain}
            </Col>
            <Col md={secContentCol__} lg={secContentCol__Lg} className={secContentClass__}>
                {secContent__}
            </Col>
        </>
    )
}

const CardFlagSec = () => {
    return (
        <>
            {CardFlagData.map((item, key) => {
                return (
                    <Col md={6} lg={6} xl={4} className='mx-0' key={key}>
                        <div className="cardFlag">
                            <div className="cardFlagImgHeading">
                                <div className="cardFlagImg">
                                    <img src={item.cardFlagSecImg} alt="" />
                                </div>
                                <div className="cardFlagHeadings">
                                    <div className="cardFlagHeading">
                                        <h3>{item.cardFlagSecHeading}</h3>
                                    </div>
                                    <div className="cardFlagHeadingTwo">
                                        <h4>{item.cardFlagSecHeadingTwo}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="cardFlagContent">
                                <p>{item.cardFlagSecContent}</p>
                            </div>
                            <div className="cardFlagLine"></div>
                            <div className="cardFlagLink">
                                <Link to={item.cardFlagSecLink}>{item.cardFlagSecLinkText}</Link>
                            </div>
                        </div>
                    </Col>
                )
            })}
        </>
    )
}

const TwoHeadingCom = ({ TwoHeadingContent, TwoHeadingContentImg, TwoHeadingUpper }) => {
    return (
        <>
            <Col md={6} className='align-self-center'>
                <div className="twoHeading">
                    <div className="upperHeading">
                        <h2>{TwoHeadingUpper}</h2>
                    </div>
                    {TwoHeadingContent}
                </div>
            </Col>
            <Col md={6} className='align-self-center'>
                {TwoHeadingContentImg}
            </Col>
        </>
    )
}

const OurExperticesCom = ({ item }) => {
    return (
        <>
            <div className="ourExperticesComD">
                <div className="ourExperticesComDItem1">
                    <div className="ourExperticesComDItem1Heading">
                        <div className="upperHeading">
                            <h2>{item.upperHeadingCon}</h2>
                        </div>
                        <div className="secHeading">
                            <h3>{item.mainHeadingCon}</h3>
                        </div>
                    </div>
                    <div className="ourExperticesComDItem1Img">
                        <img src={item.imgExpertices} alt="" />
                    </div>
                </div>
                <div className="secContent">
                    <p>{item.contentPara}</p>
                </div>
                <div className="secUlItems">
                    {item.unorderlistItem}
                </div>
            </div>
        </>
    )
}

const FaqsCom = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [activeIndex1, setActiveIndex1] = useState(null);

    const toggleFAQ = (index, faqSet) => {
        if (faqSet === 1) {
            if (index === activeIndex) {
                setActiveIndex(null);
            } else {
                setActiveIndex(index);
            }
        } else if (faqSet === 2) {
            if (index === activeIndex1) {
                setActiveIndex1(null);
            } else {
                setActiveIndex1(index);
            }
        }
    };

    return (
        <>
            <Col md={6}>
                {
                    FaqsData.map((item, index) => {
                        return (
                            <div className="faqItem" key={index}>
                                <div className={activeIndex === index ? "faqActive faqItemHeading" : "faqItemHeading"} onClick={() => toggleFAQ(index, 1)}>
                                    <div className="faqHeading">
                                        <div className="upperHeading">
                                            <h4>{item.faqItemUpperHeading}</h4>
                                        </div>
                                        <div className="lowerHeading">
                                            <h5>{item.faqItemLowerHeading}</h5>
                                        </div>
                                    </div>
                                    <div className="faqArrow">
                                        <FaqArrow alt="Arrow" />
                                    </div>
                                </div>
                                {activeIndex === index &&
                                    <div className="faqItemContent">
                                        {item.faqItemContent}
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </Col>
            <Col md={6}>
                {
                    FaqsData1.map((item, index) => {
                        return (
                            <div className="faqItem" key={index}>
                                <div className={activeIndex1 === index ? "faqActive faqItemHeading" : "faqItemHeading"} onClick={() => toggleFAQ(index, 2)}>
                                    <div className="faqHeading">
                                        <div className="upperHeading">
                                            <h4>{item.faqItemUpperHeading}</h4>
                                        </div>
                                        <div className="lowerHeading">
                                            <h5>{item.faqItemLowerHeading}</h5>
                                        </div>
                                    </div>
                                    <div className="faqArrow">
                                        <FaqArrow alt="Arrow" />
                                    </div>
                                </div>
                                {activeIndex1 === index &&
                                    <div className="faqItemContent">
                                        {item.faqItemContent}
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </Col>
        </>
    )
}

const Slider = () => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [activeSlideImg, setActiveSlideImg] = useState(SliderData[0].sliderItemImg);

    // const handleSlideClick = (swiper) => {
    //     const index = swiper.clickedIndex;
    //     if (index) {
    //         swiper.slideTo(index);
    //         setActiveSlideIndex(index);
    //         setActiveSlideImg(SliderData[index].sliderItemImg);
    //     }
    // };

    const handleSlideClick = (swiper) => {
        const index = swiper.clickedIndex;
        if (index >= 0 && index < SliderData.length) {
            swiper.slideTo(index);
            setActiveSlideIndex(index);
            setActiveSlideImg(SliderData[index].sliderItemImg);
        }
    };


    const handleSlideChange = (swiper) => {
        setActiveSlideImg(SliderData[swiper.activeIndex].sliderItemImg);
        setActiveSlideIndex(swiper.activeIndex);
    };

    // const handleSlideChange = (swiper) => {
    //     const centeredSlideIndex = Math.floor(swiper.slides.length / 2) + swiper.realIndex;
    //     setActiveSlideIndex(centeredSlideIndex);
    //     setActiveSlideImg(SliderData[centeredSlideIndex].sliderItemImg);
    // };

    return (
        <>
            <div className="slideDetails">
                <div className="sliderImgClick">
                    <img src={activeSlideImg} alt="" />
                </div>
                <div className="sliderContent">
                    <h3>{SliderData[activeSlideIndex].title}</h3>
                    <p>{SliderData[activeSlideIndex].description}</p>
                    <p>{SliderData[activeSlideIndex].sliderItemMainDetail}</p>
                </div>
            </div>
            <Swiper
                slidesPerView={1}
                spaceBetween={40}
                centeredSlides={true}
                centeredSlidesBounds={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                onSwiper={(swiper) => {
                    swiper.on("click", () => {
                        handleSlideClick(swiper);
                    });
                    swiper.on("slideChange", () => {
                        handleSlideChange(swiper);
                    });
                }}
                breakpoints={{
                    400: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    450: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    576: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                    768: {
                        slidesPerView: 5,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 6,
                        spaceBetween: 50,
                    },
                    1200: {
                        slidesPerView: 7,
                        spaceBetween: 40,
                    },
                }}
            >
                {SliderData.map((item, key) => {
                    return (
                        <SwiperSlide key={key}>
                            <img src={item.sliderImg} alt="" />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </>
    );
};


const SectionSec = ({ secUpperHeading, secHeading, secContent, secImg, secColImgClass, secRowClass, secColContentClass, secSectionClass }) => {
    return (
        <section className={secSectionClass}>
            <Container>
                <Row className={secRowClass}>
                    <Col md={6} className={secColContentClass}>
                        <div className="sectionContent">
                            <div className="secUpperHeading">
                                <h2>{secUpperHeading}</h2>
                            </div>
                            <div className="secHeading">
                                <h3>{secHeading}</h3>
                            </div>
                            <div className="secContent">
                                {secContent}
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className={secColImgClass}>
                        <div className="hoverSecImg secImg">
                            <img src={secImg} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

const getRandomColorWithOpacity = (opacity = 0.5) => {
    const r = Math.floor(Math.random() * 128);
    const g = Math.floor(Math.random() * 128);
    const b = Math.floor(Math.random() * 128);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const ProjectCard = ({ projectCardLink, projectCardImg, projectCardHeading, projectCardDesc }) => {
    const randomColorWithOpacity = getRandomColorWithOpacity(0.84);
    return (
        <Col lg={3} md={4} sm={6} xs={12}>
            <a href={projectCardLink} target='_blank' className="projectCard" style={{ "--color": randomColorWithOpacity }}>
                <div className="projectCardImg">
                    <img src={projectCardImg} alt="" />
                </div>
                <div className="projectCardHeading">
                    <h4>{projectCardHeading}</h4>
                </div>
                <div className="projectCardContent">
                    <p>{projectCardDesc}</p>
                </div>
            </a>
        </Col>
    )
}

const TechizhGlanceSection = ({ galanceClass, galanceUperHeading, galanceHeading, galanceText }) => {
    return (
        <>
            <section className='py-5'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={`techizhAtSec ${galanceClass}`}>
                                <div className="sectionContent">
                                    <div className="secUpperHeading">
                                        <h2>{galanceUperHeading}</h2>
                                    </div>
                                    <div className="secHeading">
                                        <h3>{galanceHeading}</h3>
                                    </div>
                                    <Col md={6}>
                                        <div className="secContent">
                                            {galanceText}
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

const ChatWidget = () => {
    const whatsappNumber = "+19059618755";
    const message = encodeURIComponent("Hello! I need assistance.");
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${message}`;

    return (
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="chat-widget">
            <WhatsApp />
        </a>
    );
};

const CounterSection = () => {
    return (
        <section className='counterSec'>
            <Container>
                <Row className='justify-content-center'>
                    <Col md={10}>
                        <div className="counterSection">
                            {stats.map((stat, index) => (
                                <div key={index} className="counterSectionItem">
                                    <h4 className="counterSectionItemHeading">
                                        <CountUp start={0} end={stat.value} duration={5.5} />+
                                    </h4>
                                    <p className="counterSectionItemPara">{stat.label}</p>
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export {
    Button,
    Loading,
    CardSec,
    Banner,
    AboutSec,
    Headings,
    CorporateCard,
    SecImg,
    CardFlagSec,
    TwoHeadingCom,
    OurExperticesCom,
    FaqsCom,
    Slider,
    SectionSec,
    ProjectCard,
    TechizhGlanceSection,
    ChatWidget,
    CounterSection,
}